@use 'transitions' as *;

// clearfix helper
@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

// resets an <a> tag's styles
@mixin link-reset() {
	display: inline-block;
	font-size: inherit;
	color: inherit;
	font-weight: normal;
	text-decoration: none;
	outline: none;
}

// creates an on-hover/focus underline for links
@mixin link-underline($color: palette(black)) {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background: $color;
		opacity: 0;
		transform: scaleX(0.01);
		transform-origin: bottom right;
		transition: transform 500ms $ease-out-quint, opacity 400ms 100ms $ease-out-quint;
	}

	&:hover, &:focus {
		color: $color;
		outline: none;

		&::before {
			opacity: 1;
			transform: scaleX(1);
			transform-origin: bottom left;
			transition: transform 500ms $ease-out-quint, opacity 100ms $ease-out-quint;
		}
	}
}

// custom scrollbar styles for webkit
@mixin styled-scrollbar($style: default) {
	&::-webkit-scrollbar {
		width: 0.5rem;
		height: 0.5rem;
	}

	$track: palette(white);
	$thumb: palette(gray);
	$thumbHover: palette(black);

	@if $style == inverted {
		$track: palette(black);
		$thumb: palette(red);
		$thumbHover: palette(white);
	}

	&::-webkit-scrollbar-track {
		background: $track;
		border-left: 1px solid $track;
	}

	&::-webkit-scrollbar-thumb {
		background: $thumb;
		border-radius: 1rem;
		border: 2px solid $track;

		&:hover {
			background: $thumbHover;
		}
	}
}

// dynamic masthead background helper:
// $style determines the image scope to use for this page
// $overlay can be set to "default", "dark", "none", or a custom background color
@mixin masthead-background($style: default, $overlay: default) {
  body.use-masthead-bg &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-position: center;
    background-size: cover;
    pointer-events: none;
    z-index: -2;

    // we use image-set calls here so the browser can take over the image selection
    background-image: url("~images/masthead/#{$style}/480.jpg");
    background-image: image-set(
      url("~images/masthead/#{$style}/480.jpg") 1x,
      url("~images/masthead/#{$style}/480@2x.jpg") 2x
    );

    @media screen and (min-width: 481px) {
      background-image: url("~images/masthead/#{$style}/960.jpg");
      background-image: image-set(
        url("~images/masthead/#{$style}/960.jpg") 1x,
        url("~images/masthead/#{$style}/960@2x.jpg") 2x
      );
    }

    @media screen and (min-width: 961px) {
      background-image: url("~images/masthead/#{$style}/1920.jpg");
      background-image: image-set(
        url("~images/masthead/#{$style}/1920.jpg") 1x,
        url("~images/masthead/#{$style}/1920@2x.jpg") 2x
      );
    }

    @media screen and (min-width: 1921px) {
      background-image: url("~images/masthead/#{$style}/2560.jpg");
      background-image: image-set(
        url("~images/masthead/#{$style}/2560.jpg") 1x,
        url("~images/masthead/#{$style}/1920@2x.jpg") 2x
      );
    }
  }

  body.use-masthead-bg &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
    z-index: -1;

    @if ($overlay == default) {
      background: linear-gradient(to bottom, rgba(black, 0.25), rgba(black, 0.75));
    } @if ($overlay == dark) {
      background: linear-gradient(to bottom, rgba(black, 0.75), rgba(black, 0.9));
    } @else if ($overlay != none) {
      background: $overlay;
    }
  }
}
