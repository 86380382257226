@use 'stylesheets/setup' as *;

[data-accordion] {
  [data-accordion-toggle] {
    cursor: pointer;
  }

  [data-accordion-item] {
    &[data-accordion-open="false"] [data-accordion-content] {
      height: 0;
    }

    [data-accordion-content] {
      overflow: hidden;
    }
  }
}
