@use 'stylesheets/setup' as *;

$slices: (xs, sm, md, lg, xl, xxl);
@for $i from 1 through length($slices) {
  $slice: nth($slices, $i);

  @if $i != 1 {
    @include slice-from($slice) {
      .h-from-#{$slice} { display: none !important; }
    }
  }

  @if $i != length($slices) {
    @include slice-to($slice) {
      .h-to-#{$slice} { display: none !important; }
    }
  }

  @include slice-at($slice) {
    .h-at-#{$slice} { display: none !important; }
  }

  @for $j from $i through length($slices) {
    $slice2: nth($slices, $j);

    @if $i != $j {
      @include slice-between($slice, $slice2) {
        .h-between-#{$slice}-#{$slice2} { display: none !important; }
      }
    }
  }
}
