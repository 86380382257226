@use 'stylesheets/setup' as *;

.ui-slideout-cart {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: calc(100% - 2rem);
  max-width: 24rem;
  color: palette(black);
  z-index: 1000;
  box-shadow: 0 0 100px rgba(black, 0.5);

  /**
   * SlideoutCart white BG must be separately-sized from its contents to optimize
   * for mobile height resizing logic.
   * The white backdrop needs to be 100vh, but the content itself should only be 100%
   * to prevent overflowing at the bottom.
   * See: https://developers.google.com/web/updates/2016/12/url-bar-resizing
   */
  .slideout-cart-background {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: palette(white);
    z-index: -1;
  }

  &.loading {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(palette(white), 0.75);
      border-radius: vars(border-radius, large);
      z-index: 1;
    }

    &::after {
      content: "";
      position: absolute;
      top: calc(50% - 4rem);
      left: calc(50% - 4rem);
      width: 8rem;
      height: 8rem;
      background-image: url("../../../public/loading-block-small.gif");
      background-size: cover;
      background-position: center;
      z-index: 1;
    }
  }

  &.empty {
    background: palette(offwhite);

    .body.cart-items {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      .cart-empty {
        color: palette(gray);
        font-style: italic;
      }
    }

    .footer {
      border: none;

      .amounts {
        display: none;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    padding: 0.5rem 1rem;

    .title {
      flex: auto;
      font-family: vars(font-heading);
      font-size: 1.5rem;
      line-height: 2rem;
    }

    .icon {
      flex: 0 0 auto;
      padding: 1rem;
      margin-right: -1rem;
      font-size: 0;
      color: palette(black);

      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .body.cart-banners {
    flex: 0 0 auto;
    padding: 0 1rem;
  }

  .body.cart-items {
    flex: auto;
    padding: 1rem;
    overflow: auto;

    .ui-cart-item {
      margin-bottom: 1rem;
    }
  }

  .body.cart-upsell-message {
    flex: 0 0 auto;
    padding: 1rem;
    color: palette(dullyellow);
    background: palette(yellow, light);
    font-weight: 700;
    border-top: 1px solid palette(border);
  }

  .body.cart-upsell {
    flex: 0 0 auto;
    padding: 1rem;
    background: palette(offwhite);
    border-top: 1px solid palette(border);

    .ui-button {
      margin: 1rem 0 0;

      @include slice-from(xs) {
        margin: 1rem 0 0 5rem;
      }
    }
  }

  .footer {
    flex: 0 0 auto;
    padding: 1rem;
    border-top: 1px solid palette(border);

    .ui-button {
      margin: 1rem 0 0;
    }

    .amounts {
      .cost-row {
        display: flex;
        color: palette(gray);
        text-transform: uppercase;
        line-height: 1.2rem;

        &.cost-coupon,
        &.cost-credits {
          color: palette(green);
        }

        &.cost-icepacks {
          color: palette(blue);
        }

        &.cost-total {
          margin-top: 1rem;
          font-weight: 700;

          .amount { color: palette(red); }
        }

        .label {
          flex: auto;
        }

        .amount {
          flex: 0 0 auto;
          font-weight: 700;
        }
      }
    }
  }

  @include slice-from(md) {
    .header {
      padding: 1rem 2rem;
    }

    .body {
      padding: 2rem;
    }

    .footer {
      padding: 1rem 2rem;

      .ui-button {
        margin: 1rem -1rem 0;
        width: calc(100% + 2rem);
      }
    }
  }
}

.slideout-cart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: rgba(black, 0.5);
  z-index: 999;
  cursor: pointer;
}
