@use 'stylesheets/setup' as *;

html, body {
  font-family: vars(font-body);
  font-size: 14px;

  @include slice-from(sm) {
    font-size: 16px;
  }
}

h1, h2, h3 {
  margin: 2em 0 1em;
  line-height: 1.25;
  font-family: vars(font-heading);
}

h3 {
  font-family: vars(font-body);
  font-size: 1rem;
  font-weight: 700;
  color: palette(red);
}

p {
  line-height: 1.5;
}

a {
  text-decoration: none;
}

ul, ol {
  padding-left: 1.5rem;
  li {
    margin: 1rem 0;
  }
}

hr {
  margin: 2rem 0;
  border: none;
  border-bottom: 1px solid palette(border);
}

blockquote {
  margin: 1rem 0;
  padding: 0 1rem;
  border-left: 3px solid palette(border);
  color: palette(gray, dark);
}

table {
  width: 100%;
  border-collapse: collapse;

  th, td {
    vertical-align: top;
    text-align: left;
    padding: 0;

    &:not(:last-child) {
      padding-right: 2rem;
    }

    h1, h2, h3 {
      margin: 1rem 0;
      line-height: 1.5;
    }
  }
}

dl {
  display: block;

  @include slice-from(md) { column-width: 16rem; }
  @include slice-from(lg) { column-width: 20rem; }
  @include slice-from(xl) { column-width: 24rem; }

  dt {
    margin-right: 1rem;
    font-size: 0.75rem;
    font-weight: 700;
    color: palette(red);
    text-transform: uppercase;
  }

  dd {
    margin: 0.5rem 0 1rem;
    margin-right: 1rem;
  }
}

svg {
  path[fill="black"], circle[fill="black"], rect[fill="black"] {
    fill: currentColor;
  }

  path[stroke="black"], circle[stroke="black"], rect[stroke="black"] {
    stroke: currentColor;
  }
}
