@use 'stylesheets/setup' as *;

.ui-cart-item {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 700;

  .ui-sale-thumbnail {
    flex: 0 0 4rem;
    margin-right: 1rem;
  }

  .content {
    flex: auto;
  }

  .upsell-message {
    margin-bottom: 1rem;
    color: palette(red);
    font-size: 0.8rem;
  }

  .producer {
    color: palette(gray);
    font-size: 0.9rem;
  }

  .name {
    font-size: 0.9rem;

    .badge {
      display: inline-block;
      margin-left: 0.33rem;
      padding: 0 0.5rem;
      line-height: 0.9rem;
      font-size: 0.7rem;
      font-weight: 700;
      text-transform: uppercase;
      background: palette(red);
      color: palette(white);
      border-radius: 2rem;
    }
  }

  .details {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.5rem;

    .quantity-fields {
      display: flex;

      .quantity-button {
        padding: 0 0.5rem;
        line-height: 1.5rem;
        font-size: 0.8rem;
        color: palette(gray);
        border: 1px solid palette(border);
        background: none;
        appearance: none;
        cursor: pointer;

        &:hover {
          color: palette(red);
        }

        &:first-child {
          border-top-left-radius: vars(border-radius);
          border-bottom-left-radius: vars(border-radius);
        }

        &:last-child {
          border-top-right-radius: vars(border-radius);
          border-bottom-right-radius: vars(border-radius);
        }
      }

      .quantity-count {
        padding: 0 0.5rem;
        line-height: 1.5rem;
        color: palette(gray);
        border-top: 1px solid palette(border);
        border-bottom: 1px solid palette(border);
      }
    }

    .quantity {
      color: palette(gray);
    }

    .price {
      .unit {
        margin-right: 0.5em;
        color: palette(gray);
        font-size: 0.8rem;
      }

      .total {
        color: palette(red);
      }
    }
  }

  &.expired {
    .ui-sale-thumbnail {
      filter: grayscale(1);
    }

    .name {
      color: palette(gray);
    }

    .details.muted {
      .trash-button {
        padding: 0 0.5rem;
        line-height: 1.5rem;
        font-size: 0.8rem;
        color: palette(gray);
        border: 1px solid palette(border);
        background: none;
        appearance: none;
        cursor: pointer;
        border-radius: vars(border-radius);

        &:hover {
          color: palette(red);
        }
      }

      .message {
        font-weight: 400;
        font-style: italic;
        color: palette(gray);
      }
    }
  }
}
