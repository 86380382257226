@use 'stylesheets/setup' as *;

[data-modal] {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: 1000;
  background: rgba(black, 0.5);

  [data-modal-toggle] {
    cursor: pointer;
  }

  .ui-modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    margin: 0 auto;
    max-width: calc(#{slice-start(md)} - 4rem); // default: md
  }

  &.modal-xs .ui-modal-container { max-width: calc(#{slice-start(sm)} - 8rem); }
  &.modal-sm .ui-modal-container { max-width: calc(#{slice-start(sm)} - 4rem); }
  &.modal-md .ui-modal-container { max-width: calc(#{slice-start(md)} - 4rem); }
  &.modal-lg .ui-modal-container { max-width: calc(#{slice-start(lg)} - 6rem); }
  &.modal-xl .ui-modal-container { max-width: calc(#{slice-start(xl)} - 8rem); }

  .ui-modal {
    margin: 4rem 0;
    background: palette(white);
    border-radius: vars(border-radius, large);
    box-shadow: 0 0 4rem rgba(black, 0.25);

    .modal-header {
      display: flex;

      &.sticky {
        background: palette(white);
        position: sticky;
        top: 0;
        border-top-left-radius: vars(border-radius, large);
        border-top-right-radius: vars(border-radius, large);
        z-index: 100;
      }

      .title {
        flex: auto;
        display: block;
        padding: 1rem 2rem;
        font-size: 0.9rem;
        line-height: 1rem;
        font-weight: 700;
        color: palette(gray);
        text-transform: uppercase;
      }

      button.modal-toggle {
        margin: 0.5rem 1rem;
        padding: 0.5rem;
        font-size: 0;
        background: none;
        appearance: none;
        border: none;

        svg {
          width: 1rem;
          height: 1rem;
          color: palette(gray);
        }

        &:hover {
          svg {
            color: palette(red);
          }
        }
      }
    }

    .modal-body {
      padding: 2rem;

      &.muted {
        background: palette(offwhite);
      }

      + .modal-body {
        border-top: 1px solid palette(border);
      }
    }

    .modal-footer {
      padding: 1rem 2rem;
      background: palette(offwhite);
      border-top: 1px solid palette(border);
      border-bottom-left-radius: vars(border-radius, large);
      border-bottom-right-radius: vars(border-radius, large);

      &.sticky {
        position: sticky;
        bottom: 0;
        z-index: 100;
      }

      .ui-button {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  @include slice-to(sm) {
    .ui-modal-container {
      justify-content: flex-end;
    }

    .ui-modal {
      margin-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .modal-footer {
        padding-bottom: 3rem;
      }
    }
  }
}
