@use 'stylesheets/setup' as *;

.ui-panel {
  position: relative;
  background: palette(white);
  margin: 0 -2rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(palette(white), 0.25);
    border-radius: vars(border-radius, large);
    z-index: 1;
    pointer-events: none;
    transition: all 250ms ease;
    opacity: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 4rem);
    left: calc(50% - 4rem);
    width: 8rem;
    height: 8rem;
    background-image: url("../../../public/loading-block-small.gif");
    background-size: cover;
    background-position: center;
    pointer-events: none;
    z-index: 1;
    transition: all 250ms ease, transform 100ms 150ms ease-out;
    transform: scale(0.75);
    opacity: 0;
  }

  &.loading {
    min-height: 4rem;

    &::before {
      pointer-events: all;
      opacity: 1;
    }

    &::after {
      pointer-events: all;
      transform: none;
      opacity: 1;
    }
  }

  .panel-header {
    padding: 1rem 2rem;
    line-height: 1rem;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    color: palette(gray);
  }

  .panel-body {
    padding: 1rem 2rem;

    &.muted {
      background: palette(offwhite);
    }

    @include slice-from(sm) {
      &:not(.muted) + .panel-body.muted,
      &.muted + .panel-body:not(.muted) {
        border-top: 1px solid palette(border);
      }
    }
  }

  .panel-footer {
    padding: 2rem;
    background: palette(offwhite);

    @include slice-from(sm) {
      padding: 1rem 2rem;
      border-top: 1px solid palette(border);
    }

    .ui-button, .ui-button-group > .ui-button {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .panel-signup {
    padding: 1rem 2rem;
    background: palette(offwhite);

    .ui-button, .ui-button-group > .ui-button {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  @include slice-from(sm) {
    margin: 0;
    border: 1px solid palette(border);
    border-radius: vars(border-radius, large);

    &.loading {
      &::before {
        background: rgba(palette(offwhite), 0.25);
        border-radius: vars(border-radius, large);
      }
    }

    .panel-header {
      padding: 1rem 2rem;
      line-height: 1rem;
      font-size: 0.75rem;
      font-weight: 700;
      text-transform: uppercase;
      color: palette(gray);
      background: palette(offwhite);
      border-bottom: 1px solid palette(border);
      border-top-left-radius: vars(border-radius, large);
      border-top-right-radius: vars(border-radius, large);
    }

    .panel-body {
      padding: 2rem;
    }

    .panel-footer {
      padding: 1rem 2rem;
      border-bottom-left-radius: vars(border-radius, large);
      border-bottom-right-radius: vars(border-radius, large);
    }

    .panel-signup {
      padding: 1rem 2rem;
      color: palette(gray);
      background: palette(offwhite);
      border-bottom: 1px solid palette(border);
      border-top-left-radius: vars(border-radius, large);
      border-top-right-radius: vars(border-radius, large);
    }
  }
}
