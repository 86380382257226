@use 'stylesheets/setup' as *;

// generic form styles
.ui-form {
  .form-label {
    display: block;
    margin: 1rem 0 0;
    color: palette(gray);
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 1rem;
    text-transform: uppercase;

    + .ui-input, + .ui-select, + .ui-date-select, + .ui-checkbox {
      margin-top: 0.5rem;
    }
  }
}

// reset all input fields
input, select {
  line-height: 3rem;
  border-radius: 3rem;
}

// generic styles for box-type field
.ui-input, .ui-select, .ui-date-select {
  position: relative;
  margin: 1rem 0;

  label {
    display: block;
    margin: 0 0 0.25rem;
    color: palette(gray);
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 1rem;
    text-transform: uppercase;
  }

  .input-error {
    display: block;
    margin: 0.5rem 1rem 0;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 1rem;
    color: palette(red);
  }

  .input-icon {
    display: inline-block;
    position: absolute;
    top: 1px;
    left: 0.25rem;
    padding: 0.75rem;
    font-size: 0;
    pointer-events: none;

    &.right {
      left: auto;
      right: 0.25rem;
    }

    svg, img {
      width: 1.5rem;
      height: 1.5rem;
      color: palette(gray, light);

      &[data-fa-i2svg],
      &.svg-inline--fa {
        width: 1rem;
        height: 1rem;
        margin: 0.25rem;
      }
    }

    img {
      opacity: 0.5;
    }
  }

  label ~ .input-icon {
    top: calc(1.25rem + 1px);
  }

  .input-icon + input,
  .input-icon + select {
    padding-left: 3.25rem;
  }

  .input-icon.right + input,
  .input-icon.right + select {
    padding-left: 1rem;
    padding-right: 3.25rem;
  }

  input, select {
    display: block;
    padding: 0.75rem 1rem;
    border-radius: 3rem;
    line-height: 1.5rem;
    width: 100%;
    appearance: none;
    background: none;
    border: 1px solid palette(border);
    outline: none;
    transition: all 150ms ease;

    &::placeholder, option[value=""] {
      color: palette(gray);
      font-style: italic;
    }

    &:hover {
      border-color: palette(gray);
    }

    &:focus {
      border-color: palette(black);
      box-shadow: 0 0 0 3px rgba(palette(gray), 0.25);
    }

    &:disabled {
      background: palette(offwhite);
      color: palette(gray);
    }
  }

  input[type="date"] {
    &::-webkit-datetime-edit-month-field,
    &::-webkit-datetime-edit-day-field,
    &::-webkit-datetime-edit-year-field {
      color: palette(black);
    }
  }

  &.has-error {
    input, select {
      border-color: palette(red);
      color: palette(red);

      &:hover {
        border-color: palette(red, bright);
      }

      &:focus {
        border-color: palette(red, bright);
        box-shadow: 0 0 0 3px rgba(palette(red), 0.25);
      }
    }
  }

  &.inverted {
    label {
      color: palette(gray, light);
    }

    input, select {
      border-color: palette(border, inverted);
      color: palette(white);

      option {
        color: palette(black);
      }

      &::placeholder, option[value=""] {
        color: palette(gray, light);
      }

      &:hover {
        border-color: palette(gray, light);
      }

      &:focus {
        border-color: palette(white);
        box-shadow: 0 0 0 3px rgba(palette(gray), 0.25);
      }

      &:disabled {
        background: rgba(palette(white), 0.25);
        color: palette(gray);
      }
    }
  }
}

// built-in date_select styles
.ui-date-select {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;

  // dirty hack, there are still issues with built-in forms having wrappers
  .field_with_errors {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }

  label {
    flex: 100%;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  select {
    flex: calc(30% - 1rem);
    margin: 0 0.5rem 1rem;
    min-width: 4rem;

    // month selector
    &:first-of-type {
      flex: calc(40% - 1rem);
      min-width: 8rem;
    }
  }
}
