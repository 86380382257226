@use 'stylesheets/setup' as *;

.ui-switch {
  position: relative;
  margin: 1rem 0;
  display: flex;

  &:hover {
    color: palette(red);
  }

  label {
    flex: auto;
    display: block;
    padding-right: 3.5rem;
    cursor: pointer;
    font-weight: 700;
    line-height: 1.25rem;
    transition: all 250ms ease;

    small {
      display: block;
      margin-top: 0.25rem;
      font-size: 1rem;
      color: palette(gray);
      font-weight: 400;
      transition: all 250ms ease;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  .switch-knob {
    position: absolute;
    top: 0;
    right: 0;
    margin-left: 1rem;
    height: 1.5rem;
    width: 2.5rem;
    border-radius: 2rem;
    background: palette(gray);
    transition: all 250ms ease;

    &::before {
      content: "";
      position: absolute;
      top: -6px;
      left: -6px;
      right: -6px;
      bottom: -6px;
      border: 1px solid transparent;
      opacity: 0;
      border-radius: 3rem;
      transition: all 150ms ease;
      pointer-events: none;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0.25rem;
      left: 0.25rem;
      height: 1rem;
      width: 1rem;
      background: palette(white);
      border-radius: 2rem;
      transition: all 250ms ease;
    }
  }

  input[type="checkbox"]:checked ~ label .switch-knob {
    background: palette(red);

    &::after {
      left: #{ 2.5rem - 1rem - 0.25rem };
      background: palette(white);
    }
  }

  input[type="checkbox"]:hover ~ label {
    color: palette(red);

    small {
      color: palette(gray, dark);
    }

    .switch-knob {
      background: palette(gray, dark);
    }
  }

  input[type="checkbox"]:checked:hover ~ label .switch-knob {
    background: palette(red, bright);

    &::before {
      border-color: palette(red, bright);
    }
  }

  input[type="checkbox"]:focus ~ label {
    color: palette(red);

    small {
      color: palette(gray, dark);
    }

    .switch-knob {
      &::before {
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        border-color: palette(gray);
        opacity: 1;
      }
    }
  }

  input[type="checkbox"]:checked:focus ~ label .switch-knob {
    &::before {
      border-color: palette(red);
    }
  }
}
