@use 'stylesheets/setup' as *;

@import '~normalize.css';

html, body {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
}

*, *::before, *::after {
  box-sizing: border-box;
}
