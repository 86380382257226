@use 'stylesheets/setup' as *;

[data-tippy-root] {
  .tippy-content {
    a {
      color: palette(white);
      font-weight: 700;

      @include link-underline(palette(white));
    }
  }
}
