@use 'stylesheets/setup' as *;

// override react-datepicker vars
$datepicker__background-color: palette(offwhite);
$datepicker__border-color: palette(border);
$datepicker__highlighted-color: palette(red);
$datepicker__muted-color: palette(gray);
$datepicker__selected-color: palette(red);
$datepicker__text-color: palette(black);
$datepicker__header-color: palette(red);
$datepicker__border-radius: vars(border-radius);
$datepicker__day-margin: 0.125rem;
$datepicker__font-size: 0.8rem;
$datepicker__font-family: vars(font-body);
$datepicker__item-size: 2rem;
$datepicker__margin: 0.125rem;

@import 'react-datepicker/src/stylesheets/datepicker.scss';

// custom react-datepicker styles
.ui-input.date-picker {
  .react-datepicker__day-names {
    margin-top: 0.5rem;
  }

  .react-datepicker__day-name {
    line-height: 1rem;
    text-transform: uppercase;
    color: palette(gray);
    font-weight: 700;
    font-size: 0.6rem;
  }

  .react-datepicker__day--selected {
    font-weight: 700;
  }

  .react-datepicker__navigation-icon:before {
    border-width: 1px 1px 0 0;
  }
}
