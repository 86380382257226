@use 'stylesheets/setup' as *;
@use 'sass:math';

@use 'nav_mega_menu';
@use 'user_popup';

$logo-size: 4rem;
$logo-size-large: 6rem;

// gsap animation duration
$transition-duration: 500ms;

// setup global var for dynamic header pad sizing
body {
  --header-nav-height: #{$logo-size + 2rem};

  @include slice-from(md) {
    --header-nav-height: #{$logo-size-large + 2rem};

    &.has-site-banner {
      --header-nav-height: #{$logo-size-large + 4.5rem};
    }
  }
}

.site-banner {
  padding: 0.75rem 0;
  line-height: 1rem;
  background: palette(dullyellow, dark);
  color: palette(yellow);
  font-size: 0.9rem;
  font-weight: 700;
  transition: all $transition-duration ease;

  .col, .col-auto {
    display: flex;
    align-items: center;
  }

  .text {
    width: 100%;
  }

  a {
    color: palette(yellow, light);
    @include link-underline(palette(yellow, light));
  }

  .ui-button {
    display: block;
    margin: 0;
  }

  p {
    margin: 0;
  }

  @include slice-from(md) {
    text-align: center;

    .text {
      padding: 0.5rem 0;
    }

    .ui-button {
      display: inline-block;
      margin-top: 0;
      margin-left: 1rem;
    }
  }
}

.ui-header-nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: palette(white);
  z-index: 500;

  &.inverted .site-banner {
    background: palette(yellow, light);
    color: palette(dullyellow);
  }

  .nav-row {
    position: sticky;
    top: 0;
    z-index: 100;
  }

  .nav-columns {
    position: relative;
    display: flex;
    margin: 0 -1rem;
    align-items: center;
    justify-content: space-between;
    height: #{$logo-size + 2rem};

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 1rem;
      right: 1rem;
      transition: all $transition-duration ease;
    }

    .item-group {
      flex: 0 0 auto;
      display: flex;

      .menu-item {
        flex: 0 0 auto;
        display: block;
        position: relative;
        padding: 1rem;
        text-decoration: none;
        color: inherit;
        font-size: 0;

        .icon {
          width: 2rem;
          height: 2rem;

          path, rect, circle {
            transition: all $transition-duration ease;
          }
        }

        &.mega-menu-toggle {
          &.cart-active::after {
            content: "";
            position: absolute;
            left: 1.125rem;
            bottom: 1.375rem;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            box-shadow: 0 0 0 0.33rem palette(black, light);
            background: palette(red, bright);
            transition: all $transition-duration ease;
          }

          @include slice-from(md) {
            transform: scaleX(-1);

            &.cart-active::after {
              display: none;
            }
          }
        }

        &.cart {
          position: relative;

          .cart-badge {
            position: absolute;
            top: 0.9rem;
            right: 0.9rem;
            padding: 0 0.5rem;
            font-size: 0.7rem;
            font-weight: 700;
            font-family: vars(font-body);
            line-height: 1rem;
            background: palette(red, bright);
            color: palette(white);
            border-radius: 3rem;
            transition: all $transition-duration ease;

            &.empty {
              background: palette(gray, dark);
            }
          }
          &.hidden {
            display: none;
          }
        }

        &.credit {
          text-align: center;
          line-height: 1;

          .value {
            display: block;
            font-weight: bold;
            font-size: 1rem;
            color: palette(green, light);
            transition: all $transition-duration ease;
          }

          .label {
            display: block;
            margin-top: 0.25rem;
            font-weight: bold;
            font-size: 0.75rem;
            text-transform: uppercase;
            color: palette(gray);
            transition: all $transition-duration ease;
          }
        }

        &.admin {
          margin: 0.5rem;
          margin-left: 2rem;
          padding: 0.5rem;
          background: palette(red);
          border-radius: 50%;

          svg {
            color: palette(white);
          }
        }
      }
    }

    .logo {
      display: block;
      position: absolute;
      font-size: 0;
      top: calc(50% - #{ math.div($logo-size, 2) });
      left: calc(50% - #{ math.div($logo-size, 2) });

      svg {
        width: $logo-size;
        height: $logo-size;

        path, rect, circle {
          transition: all $transition-duration ease;
        }
      }
    }

    // flip layout on mobile
    @include slice-to(sm) {
      flex-direction: row-reverse;

      .item-group.secondary {
        display: none;
      }

      .logo {
        position: relative;
        padding: 0 1rem;
        top: auto;
        left: auto;
        flex: 0 0 auto;
      }
    }
  }

  // large logo and padding for desktop up
  @include slice-from(md) {
    .nav-columns {
      height: #{$logo-size-large + 2rem};

      .logo {
        top: calc(50% - #{ math.div($logo-size-large, 2) });
        left: calc(50% - #{ math.div($logo-size-large, 2) });

        svg {
          width: $logo-size-large;
          height: $logo-size-large;
        }
      }
    }
  }

  // handle inverted color transitions
  &.inverted {
    color: palette(black);

    .nav-row {
      background: palette(white);
      transition: background 150ms $transition-duration ease; // delayed transition
    }

    .nav-columns {
      &::before {
        border-color: palette(border);
      }

      .item-group {
        .menu-item {
          &.cart {
            .cart-badge {
              background: palette(red);

              &.empty {
                background: palette(gray);
              }
            }
          }

          &.credit {
            .value {
              color: palette(green);
            }

            .label {
              color: palette(gray);
            }
          }

          &.mega-menu-toggle.cart-active::after {
            box-shadow: 0 0 0 0.33rem palette(white);
            background: palette(red);
          }
        }
      }

      // override svg path colors
      .logo {
        svg {
          path[fill="#FF2A2A"] {
            fill: palette(red);
          }

          path[fill="white"] {
            fill: palette(black);
          }
        }
      }
    }
  }
}
