@use 'stylesheets/setup' as *;
@use 'sass:math';

// simple grids based on BS4 grid
$grid-gutter: 1rem;
$grid-columns: 12;

.grid-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 #{ -$grid-gutter };

  %grid-column {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding: 0 $grid-gutter;
  }

  > .col {
    @extend %grid-column;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  > .col-auto {
    @extend %grid-column;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  @for $i from 0 through $grid-columns {
    > .col-#{ $i } {
      @extend %grid-column;
      flex: 0 0 #{ math.div(100%, $grid-columns) * $i };
      max-width: 100%;
    }

    > .order-#{ $i } {
      order: $i;
    }

    > .offset-#{ $i } {
      margin-left: #{ math.div(100%, $grid-columns) * $i };
    }
  }

  $grid-bps: (xs, sm, md, lg, xl, xxl);
  @for $i from 1 through length($grid-bps) {
    $grid-bp: nth($grid-bps, $i);

    @include slice-from($grid-bp) {
      > .col-#{ $grid-bp } {
        position: relative;
        width: 100%;
        min-height: 1px; // prevent columns from collapsing when empty
        padding: 0 $grid-gutter;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      > .col-#{ $grid-bp }-auto {
        position: relative;
        width: 100%;
        min-height: 1px; // prevent columns from collapsing when empty
        padding: 0 $grid-gutter;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
      }

      // spacer column that takes up 4rem fixed
      > .col-#{ $grid-bp }-spacer {
        position: relative;
        flex: 0 0 4rem;
      }

      // col-spacer, but with a midline divider
      > .col-#{ $grid-bp }-divider {
        position: relative;
        flex: 0 0 4rem;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 50%;
          width: 1px;
          bottom: 0;
          background: rgba(palette(black), 0.1);
        }
      }

      @for $i from 2 through 8 {
        > .col-#{ $grid-bp }-spacer-#{ $i },
        > .col-#{ $grid-bp }-divider-#{ $i } {
          flex-basis: #{ $i }rem;
        }
      }

      @for $i from 0 through $grid-columns {
        > .col-#{ $grid-bp }-#{ $i } {
          position: relative;
          width: 100%;
          min-height: 1px; // prevent columns from collapsing when empty
          padding: 0 $grid-gutter;
          flex: 0 0 #{ math.div(100%, $grid-columns) * $i };
          max-width: #{ math.div(100%, $grid-columns) * $i };
        }

        > .order-#{ $grid-bp }-#{ $i } {
          order: $i;
        }

        > .offset-#{ $grid-bp }-#{ $i } {
          margin-left: #{ math.div(100%, $grid-columns) * $i };
        }
      }
    }
  }

  // grid extras
  &.align-center {
    align-items: center;
  }
}
