@use 'stylesheets/setup' as *;

.ui-sale-thumbnail {
  position: relative;
  font-size: 0;

  .legacy-photo {
    position: relative;
    text-align: center;
    width: 4rem;
    margin: auto;
    z-index: 1;

    img {
      max-width: 100%;
      width: 4rem;
      height: auto;
      border-radius: vars(border-radius);
    }
  }

  .stacked-photo {
    position: relative;
    z-index: 1;

    .image-backdrop {
      margin: auto;
      width: 4rem;
      height: 4rem;
      max-width: 100%;
      border-radius: vars(border-radius);
      overflow: hidden;

      img.backdrop-photo {
        width: 4rem;
        height: 4rem;
        object-fit: cover;
      }
    }

    .bottle-shot {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      &.aux-left  { transform: translate(-70%, -50%) scale(0.9); }
      &.aux-right { transform: translate(-30%, -50%) scale(0.9); }

      img.bottle-photo {
        height: 5rem;
        width: auto;
        max-width: none;

        &.lazyload, &.lazyloading {
          opacity: 0;
        }

        &.lazyload-error {
          display: none;
        }
      }
    }
  }

  .vault-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: vars(border-radius);
    box-shadow: 0 0 0 0.25rem palette(gray) inset;

    &.vault-1 {
      box-shadow: 0 0 0 0.25rem palette(vault, common) inset;
    }

    &.vault-2 {
      box-shadow: 0 0 0 0.25rem palette(vault, uncommon) inset;
    }

    &.vault-3 {
      box-shadow: 0 0 0 0.25rem palette(vault, rare) inset;
    }

    &.vault-4 {
      box-shadow: 0 0 0 0.25rem palette(vault, epic) inset;
    }

    &.vault-5 {
      box-shadow: 0 0 0 0.25rem palette(vault, legendary) inset;
    }
  }

  .stacked-photo {
    .vault-overlay {
      border-radius: 50%;
      z-index: -1;
    }
  }

  .vault-tier {
    position: absolute;
    padding: 0.15rem 0.3rem;
    left: 50%;
    bottom: 0;
    font-weight: 700;
    font-size: 0.6rem;
    text-transform: uppercase;
    border-radius: 1rem;
    white-space: nowrap;
    transform: translateX(-50%);
    overflow: hidden;
    z-index: 1;

    &.vault-1 {
      background: palette(vault, common);
      color: palette(white);
    }

    &.vault-2 {
      background: palette(vault, uncommon);
      color: palette(white);
    }

    &.vault-3 {
      background: palette(vault, rare);
      color: palette(white);
    }

    &.vault-4 {
      background: palette(vault, epic);
      color: palette(white);
    }

    &.vault-5 {
      background: palette(vault, legendary);
      color: palette(white);
    }
  }

  .stacked-photo {
    .vault-tier {
      bottom: 0.25rem;
    }
  }

  .qty-badge {
    position: absolute;
    top: -0.4rem;
    right: -0.4rem;
    height: 1.6rem;
    width: 1.6rem;
    line-height: 1.6rem;
    background: palette(green);
    color: palette(white);
    font-weight: 700;
    font-size: 0.8rem;
    text-align: center;
    border-radius: vars(border-radius);
    &.negative {
      background: palette(red);
    }
  }

  &.size-medium {
    .legacy-photo {
      width: 10rem;

      img {
        width: 10rem;
        border-radius: vars(border-radius, large);
      }
    }

    .stacked-photo {
      .image-backdrop {
        width: 10rem;
        height: 10rem;
        border-radius: vars(border-radius, large);

        img.backdrop-photo {
          width: 10rem;
          height: 10rem;
        }
      }

      .bottle-shot {
        img.bottle-photo {
          height: 12.5rem;
        }
      }
    }
  }

  &.size-large {
    .legacy-photo {
      width: 20rem;

      img {
        width: 20rem;
        border-radius: vars(border-radius, large);
      }
    }

    .stacked-photo {
      .image-backdrop {
        width: 20rem;
        height: 20rem;
        border-radius: vars(border-radius, large);

        img.backdrop-photo {
          width: 20rem;
          height: 20rem;
        }
      }

      .bottle-shot {
        img.bottle-photo {
          height: 25rem;
        }
      }
    }
  }

  @include slice-to(sm) {
    &.size-large {
      .legacy-photo {
        width: 10rem;

        img {
          width: 10rem;
          border-radius: vars(border-radius, large);
        }
      }

      .stacked-photo {
        .image-backdrop {
          width: 10rem;
          height: 10rem;
          border-radius: vars(border-radius, large);

          img.backdrop-photo {
            width: 10rem;
            height: 10rem;
          }
        }

        .bottle-shot {
          img.bottle-photo {
            height: 12.5rem;
          }
        }
      }
    }
  }

  &.muted {
    .stacked-photo, .legacy-photo {
      filter: grayscale(1);
      opacity: 0.5;
    }

    .qty-badge {
      background: palette(gray, light);
      color: palette(white);
    }
  }
}
