@use 'stylesheets/setup' as *;

.ui-checkbox {
  position: relative;
  margin: 1rem 0;
  display: flex;

  &:hover {
    color: palette(red);
  }

  label {
    flex: auto;
    display: block;
    cursor: pointer;
    font-weight: 700;
    line-height: 1.25rem;

    small {
      display: block;
      margin-top: 0.25rem;
      font-size: 1rem;
      color: palette(gray);
      font-weight: 400;
    }
  }

  input[type="checkbox"] {
    flex: 0 0 auto;
    margin-right: 0.5rem;
    height: 1.25rem;
  }
}
