@use 'stylesheets/setup' as *;

.ui-footer {
  position: relative;
  padding-top: 2rem;
  background: palette(black, light);
  color: palette(white);

  .logo {
    margin: 0;

    svg {
      height: 6rem;
      width: 6rem;
    }
  }

  nav.footer-nav {
    display: block;

    @include slice-from(md) {
      display: flex;
    }

    @include slice-from(lg) {
      margin-left: 25%;
    }

    .nav-group {
      flex: 1;
      margin-bottom: 2rem;
    }

    .nav-item {
      display: block;
    }

    .nav-link {
      display: inline-block;
      padding: 0.25rem 0;
      line-height: 1.25;
      color: palette(white);
      font-weight: 700;

      @include link-underline(palette(white));

      &.highlight {
        color: palette(red, bright);

        @include link-underline(palette(red, bright));
      }
    }
  }

  .footer-subsection {
    @include slice-to(sm) {
      nav.footer-buttons {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid palette(border, inverted);
      }
    }

    @include slice-from(md) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-end;
      padding: 1rem 0;
    }
  }

  nav.footer-buttons {
    position: relative;
    flex: 0 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 0;

    .social {
      display: flex;
      flex-wrap: wrap;

      .footer-button {
        flex: auto;
        margin-right: 1rem;

        @include slice-from(md) {
          margin-left: 1rem;
          margin-right: 0;
        }
      }
    }

    .floating {
      @include slice-from(md) {
        position: absolute;
        bottom: -4rem;
        right: 0;
      }
    }

    .footer-button {
      display: inline-block;
      color: palette(black);

      img {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .global-shipping-notice {
    flex: 1;
    color: palette(gray);
    font-size: 0.75rem;
    line-height: 1.5;

    @include slice-from(md) {
      margin-right: 2rem;
      max-width: 40rem;
    }
  }

  .copyright {
    padding: 1rem 0;
    color: palette(white);
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 2rem;

    @include slice-from(md) {
      border-top: 1px solid palette(border, inverted);
    }
  }
}

.mobile-app-intro {
  padding: 1.5rem 0;
  background: palette(red);
  color: palette(white);

  .message {
    margin-bottom: 1rem;
    line-height: 1.5;
    font-weight: 700;
  }

  .download-link {
    @include link-reset;
    margin-top: 1rem;
    margin-right: 1rem;

    img {
      height: 3rem;
    }
  }

  @include slice-from(md) {
    padding: 1rem 0;

    .container {
      display: flex;
    }

    .message {
      flex: auto;
      margin-bottom: 0;
      line-height: 3rem;
    }

    .downloads {
      flex: 0 0 auto;

      .download-link {
        margin: 0;
        margin-left: 1rem;
      }
    }
  }
}
