@use 'stylesheets/setup' as *;

.nav-mega-menu {
  display: none;
  margin-top: -6rem;
  padding: 6rem 2rem 2rem;
  background: palette(white);
  color: palette(black);
  z-index: -1;
  overflow: hidden;
  box-shadow: 0 0 100px rgba(black, 0.25);

  @include slice-to(xs) {
    > .container {
      max-width: 100%;
    }
  }

  @include slice-from(md) {
    margin-top: -8rem;
    padding-top: 8rem;
  }

  .user-info-wrapper {
    margin: 2rem 0 1rem;
    display: flex;
    align-items: center;

    .user-info {
      flex: auto;

      .name {
        font-size: 1.25rem;
        font-weight: 700;
      }

      .credits {
        margin-top: 0.25rem;
        font-weight: 700;
        color: palette(gray);
        text-transform: uppercase;

        strong {
          color: palette(green);
        }
      }
    }

    .user-icon {
      display: none;
      flex: 0 0 auto;
      color: palette(gray);

      @include slice-from(xs) {
        display: block;
      }
    }
  }

  .tall-link {
    position: relative;
    display: block;
    margin: 2rem 0 0;
    padding: 2rem;
    color: palette(black);
    border-radius: vars(border-radius, large);
    outline: none;
    cursor: pointer;
    transition: all 150ms ease;

    &:hover, &:focus {
      .image-backdrop {
        &::before { opacity: 1; }
        &::after { opacity: 0; }
      }

      .label, .title {
        color: palette(white);
      }
    }

    .image-backdrop {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: vars(border-radius, large);
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 1;
        animation: none;
        border-radius: vars(border-radius, large);
        transition: all 150ms ease;
      }

      &::before, &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: vars(border-radius, large);
        background: linear-gradient(
          to bottom,
          rgba(palette(offwhite), 0.75),
          rgba(palette(offwhite), 0.95) 75%
        );
        transition: all 150ms ease;
        z-index: 1;
      }

      &::before {
        opacity: 0;
        background: linear-gradient(
          to bottom,
          rgba(palette(black), 0.25),
          rgba(palette(black), 0.5) 75%
        );
      }

      @include slice-from(md) {
        border-radius: 0;

        img, &::before, &::after {
          border-radius: 0;
        }
      }
    }

    .bottle-shot {
      display: none;
      position: absolute;
      top: -0.67rem;
      right: 2rem;
      transform: translateX(50%);
      z-index: 10;

      img {
        width: auto;
        height: 7rem;
        max-width: none;
        animation: none;
        opacity: 1;
        transition: all 150ms ease;

        &.lazyload, &.lazyloading {
          opacity: 0;
        }
      }

      img.aux {
        position: absolute;
        left: 0;
        top: 0;
        transform: scale(0.9) translateX(-20%);
        z-index: -1;

        &.right {
          transform: scale(0.9) translateX(20%);
        }
      }

      @include slice-from(xs) {
        display: block;
      }

      @include slice-from(md) {
        display: block;
        right: 4rem;
        img { height: 12rem; }
      }

      @include slice-from(lg) {
        top: -2rem;
        img { height: 16rem; }
      }

      @include slice-from(xl) {
        right: 6rem;
        img { height: 22rem; }
      }
    }

    .label {
      display: none;
      position: relative;
      margin-bottom: 4rem;
      font-size: 0.75rem;
      font-weight: 700;
      text-transform: uppercase;
      opacity: 0.5;
      z-index: 1;
      transition: all 150ms ease;
    }

    .title {
      position: relative;
      font-size: 1.5rem;
      font-family: vars(font-heading);
      transition: all 150ms ease;
      z-index: 1;
    }

    @include slice-from(md) {
      margin: 2rem 0 1rem;
      border-radius: 0;

      .label {
        display: block;
      }
    }

    @include slice-from(lg) {
      .label {
        margin-bottom: 12rem;
      }
    }

    @include slice-from(xl) {
      .label {
        margin-bottom: 18rem;
      }
    }
  }

  .submenu {
    @include slice-to(md) {
      margin: 0 2rem;
    }

    .submenu-label {
      margin: 2rem 0 1rem;
      font-size: 0.75rem;
      font-weight: 700;
      color: palette(gray);
      text-transform: uppercase;
    }

    .submenu-items {
      .item-link {
        display: inline-block;
        color: palette(black);
        font-size: 1.5rem;
        line-height: 2rem;
        font-family: vars(font-heading);
        transition: all 150ms ease;

        @include link-underline(palette(red));

        &.highlight {
          color: palette(red);
        }

        .cart-badge {
          position: absolute;
          top: calc(50% - 0.5rem);
          left: calc(100% + 0.5rem);
          padding: 0 0.5rem;
          font-size: 0.7rem;
          font-weight: 700;
          font-family: vars(font-body);
          line-height: 1rem;
          text-transform: uppercase;
          background: palette(red);
          color: palette(white);
          border-radius: 3rem;

          &.empty {
            background: palette(gray, light);
          }
        }
      }
    }
  }

  a.contest-feature {
    display: block;
    position: relative;
    margin: 2rem 0;
    padding: 2rem;
    background: rgba(palette(red), 0.1);
    color: palette(black);

    .label {
      font-weight: 700;
      font-size: 0.75rem;
      text-transform: uppercase;
    }

    .title {
      margin: 1rem 0 0;
      font-family: vars(font-heading);
      font-size: 1.5rem;
      color: palette(red);
    }

    .summary {
      font-size: 0.9rem;
    }

    .timeframe {
      margin: 1rem 0 0.5rem;
      font-weight: 700;
      font-size: 0.75rem;
      text-transform: uppercase;
      opacity: 0.5;
    }

    .icon {
      position: absolute;
      bottom: 2rem;
      right: 2rem;
      font-size: 0;

      svg {
        width: 2rem;
        height: 2rem;
        color: palette(red);
      }
    }
  }
}

.nav-mega-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: rgba(black, 0.5);
  z-index: -10;
  cursor: pointer;
}
