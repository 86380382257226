@use 'stylesheets/setup' as *;

.user-popup {
  display: block;
  min-width: 14rem;

  .popup-header {
    margin: -5px -9px 0;
    padding: calc(0.5rem + 5px) calc(0.5rem + 9px) 0;
    color: palette(black);
    text-transform: none;
    font-weight: 400;
    text-align: left;
    background: palette(offwhite);
    border-bottom: 1px solid palette(border);
    border-radius: 5px 5px 0 0;

    .label {
      font-size: 0.8rem;
      color: palette(gray);
      text-transform: uppercase;
      text-align: left;
    }

    .user-info {
      text-align: left;

      .name {
        margin: 1rem 0 1rem;
        line-height: 1.5rem;
        font-size: 1.2rem;
        font-family: vars(font-heading);
      }

      .credits {
        margin: -1rem 0 1rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.8rem;
        color: palette(gray);

        strong {
          color: palette(green, light);
        }
      }
    }
  }

  .popup-links {
    margin-top: 0.5rem;

    a.popup-link {
      display: block;
      padding: 0.25rem 0.5rem;
      line-height: 1.5rem;
      color: palette(black);
      text-align: left;
      font-weight: 700;

      &:hover, &:focus {
        color: palette(red);
      }

      &.highlight {
        color: palette(red);

        &:hover, &:focus {
          color: palette(red, bright);
        }
      }
    }
  }
}
