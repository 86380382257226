@use 'stylesheets/setup' as *;
@use 'sass:color';

.ui-message {
  position: relative;
  margin: 1rem 0;
  padding: 1rem 1.5rem;
  padding-right: 3rem;
  border-radius: vars(border-radius);

  background: palette(offwhite);
  border: 1px solid palette(border);
  color: palette(black);

  &.info {
    background: palette(skyblue, light);
    color: palette(blue);
  }

  &.success {
    background: palette(mintgreen, light);
    color: palette(green);
  }

  &.warning {
    background: palette(yellow, light);
    color: palette(yellow, dark);
  }

  &.error {
    background: palette(red, light);
    color: palette(red);
  }

  p > a {
    color: palette(red);
    @include link-underline(palette(red));
  }

  p, ul, ol {
    margin: 0;
    line-height: 1.25rem;

    + p, + ul, + ol {
      margin-top: 0.5rem;
    }
  }

  ul, ol {
    li {
      margin: 0;
    }
  }

  > .icon {
    position: absolute;
    top: 1rem;
    line-height: 1rem;
    right: 1.5rem;
  }
}
