@use 'stylesheets/setup' as *;

// simple grid container
.container {
  display: block;
  margin: 0 auto;
  max-width: calc(100% - 2rem);

	@include clearfix;

  @include slice-from(xs) {
    max-width: calc(100% - 4rem);
  }

  @include slice-from(sm) {
    max-width: calc(#{slice-start(sm)} - 4rem);
  }

  @include slice-from(md) {
    max-width: calc(#{slice-start(md)} - 4rem);
  }

  @include slice-from(lg) {
    max-width: calc(#{slice-start(lg)} - 6rem);
  }

  @include slice-from(xl) {
    max-width: calc(#{slice-start(xl)} - 8rem);
  }
}

body {
  background: palette(black, light);

  &.env-development {
    border-top: 10px solid palette(red, base);
  }

  &.env-staging {
    border-top: 10px solid palette(yellow, base);
  }
}

#wrapper {
  position: relative;
  background: palette(white);

  > #content {
    margin-top: -1px; // fix potential subpixel rendering issue
  }
}

body.noscroll {
  @media (hover: hover) and (pointer: fine) {
    overflow: hidden;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  // show IE blocker modal
  #ie-content-blocker {
    display: block !important;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 48rem;
    max-height: 48rem;
    background: palette(black, light);
    color: palette(white);
    z-index: 10000000;
    transform: translate(-50%, -50%);

    .message {
      padding: 2rem;
      line-height: 1.25;
      font-family: vars(font-heading);
      font-size: 2rem;

      img {
        margin-bottom: 2rem;
        width: auto;
        height: 4rem;
        display: block;
      }
    }
  }

  // hide all other content
  body > *:not(#ie-content-blocker) {
    display: none !important;
  }
}

// prevent collapsing the margins above on some pages (for ui-message)
.flash-messages {
  padding: 1px 0;
}

img {
  max-width: 100%;
  height: auto;

  // lazyloaded images all fall back to a loading GIF;
  // this makes sure that the loading gif isn't scaled out of its original aspect ratio
  &.lazyload, &.lazyloading {
    object-fit: contain;
    opacity: 0.5;
  }

  &.lazyloaded {
    opacity: 0;
    animation: lazyload-in 500ms forwards;
  }

  &.lazyload-error {
    background: rgba(palette(red), 0.1);
  }
}

@keyframes lazyload-in {
  from {
    transform: scale(1.05);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}

iframe {
  width: 100%;
}
