@use 'stylesheets/setup' as *;
@use 'sass:color';

header.subpage-header {
  position: relative;
  padding: calc(var(--header-nav-height) + 2rem) 0 2rem;
  background: palette(black, light);
  color: palette(white);
  z-index: 0;

  @include slice-from(sm) {
    padding: calc(var(--header-nav-height) + 2rem) 0 3rem;
  }

  @include slice-from(md) {
    padding: calc(var(--header-nav-height) + 4rem) 0 4rem;
  }

  &.has-divider {
    @include slice-from(sm) {
      padding: calc(var(--header-nav-height) + 2rem) 0 5rem;
      margin-bottom: -6rem;
    }

    @include slice-from(md) {
      padding: calc(var(--header-nav-height) + 2rem) 0 6rem;
      margin-bottom: -7rem;
    }
  }

  &.align-center {
    h1 {
      text-align: center;
    }
  }

  @include masthead-background();

  h1 {
    margin: 0;
  }

  > .container {
    position: relative;
    z-index: 3;
  }

  .masthead-divider {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    display: none;
    z-index: 2;
    font-size: 0;

    @include slice-from(sm) { display: block; }

    svg {
      width: 100%;
      height: 2rem;

      @include slice-from(sm) { height: 3rem; }
      @include slice-from(md) { height: 4rem; }
    }
  }
}

main.subpage-content {
  padding: 2rem 0 0;

  &.styled-content,
  &.bottom-padded {
    padding-bottom: 4rem;
  }

  // ---- page-specific overrides ------------------------------------------- //
  // contact page
  .support-widget {
    padding: 0 1rem;
    background: palette(offwhite);
    border: 1px solid palette(border);
    border-radius: vars(border-radius);
    height: 550px;
  }

  // press room
  .press-heading {
    h2 {
      margin: 2em 0 0;
      text-transform: uppercase;
      font-family: vars(font-body);
      font-size: 1rem;
      color: palette(gray);
    }

    .email {
      font-size: 1.25rem;
      color: palette(red);
      @include link_underline(palette(red));
    }
  }

  .press-section {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @include slice-from(sm) {
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
    }

    @include slice-from(lg) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include slice-from(xl) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .item-box {
      display: flex;
      flex-direction: column;
      background: palette(gray);
      border-radius: vars(border-radius);
      overflow: hidden;
      transition: all 150ms ease;

      &:hover, &:focus {
        background: palette(gray, dark);
      }

      @include slice-from(md) {
        flex-direction: row;
      }

      .item {
        flex: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        font-size: 0;
        color: palette(white);

        &.text {
          font-size: 1.5rem;
          font-family: vars(font-heading);
        }
      }

      .label {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        text-transform: uppercase;
        font-weight: 700;
        text-align: center;
        background: rgba(black, 0.1);
        color: palette(white);
        transition: all 150ms ease;
      }
    }
  }
}

.styled-content {
  a:not(.ui-button) {
    color: palette(red);
    @include link_underline(palette(red));
  }

  img {
    margin: 1rem 0;
  }

  iframe {
    margin: 2rem 0;
  }
}
