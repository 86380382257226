@use 'stylesheets/setup' as *;
@use 'sass:color';

@mixin button-variant($bg, $fg, $bg-hover, $bg-active) {
  background: $bg;
  color: $fg;

  &:hover, &:focus, &:focus-within {
    background: $bg-hover;
  }

  &:focus::after,
  &:focus-within::after {
    border-color: $bg-hover;
  }

  &:active {
    background: $bg-active;

    &::after {
      border-color: $bg-active;
    }
  }
}

.ui-button {
  position: relative;
  display: inline-block;
  padding: 0 1.25rem;
  margin: 1rem 0;
  background: palette(red);
  color: palette(white);
  font-family: vars(font-heading);
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 3rem;
  border: none;
  border-radius: vars(border-radius);
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: all 150ms ease;

  &::after {
    content: "";
    position: absolute;
    top: -6px;
    left: -6px;
    right: -6px;
    bottom: -6px;
    border: 1px solid transparent;
    border-radius: #{vars(border-radius) + 2px};
    transition: all 150ms ease;
    pointer-events: none;
  }

  &:focus-within::after,
  &:focus::after {
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
  }

  @include button-variant(
    palette(red),
    palette(white),
    color.scale(palette(red), $lightness: 10%),
    palette(red, bright)
  );

  &:disabled, &.disabled, &.loading {
    cursor: not-allowed;

    @include button-variant(
      palette(gray, light),
      palette(gray),
      palette(gray, light),
      palette(gray)
    );
  }

  &.loading {
    pointer-events: none;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: vars(border-radius);
      background-color: rgba(palette(gray, light), 0.9);
      background-image: url("../../../public/loading-block-small.gif");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1;
    }
  }

  &.inverted {
    @include button-variant(
      palette(red, bright),
      palette(white),
      color.scale(palette(red, bright), $lightness: -25%),
      palette(red)
    );
  }

  &.secondary {
    @include button-variant(
      palette(offwhite),
      palette(red),
      color.scale(palette(offwhite), $lightness: -5%),
      color.scale(palette(offwhite), $lightness: -10%)
    );
  }

  &.inverted.secondary {
    @include button-variant(
      palette(gray, dark),
      palette(white),
      color.scale(palette(gray, dark), $lightness: 10%),
      palette(gray)
    );
  }

  &.blue {
    @include button-variant(
      palette(blue),
      palette(white),
      color.scale(palette(blue), $lightness: 10%),
      color.scale(palette(blue), $lightness: 20%)
    );
  }

  &.inverted.blue {
    @include button-variant(
      color.scale(palette(blue), $lightness: 20%),
      palette(white),
      color.scale(palette(blue), $lightness: 10%),
      palette(blue)
    );
  }

  &.green {
    @include button-variant(
      palette(green),
      palette(white),
      color.scale(palette(green), $lightness: 10%),
      color.scale(palette(green), $lightness: 20%)
    );
  }

  &.inverted.green {
    @include button-variant(
      color.scale(palette(green), $lightness: 20%),
      palette(white),
      color.scale(palette(green), $lightness: 10%),
      palette(green)
    );
  }

  &.dullyellow {
    @include button-variant(
      palette(dullyellow),
      palette(white),
      color.scale(palette(dullyellow), $lightness: 10%),
      color.scale(palette(dullyellow), $lightness: 20%)
    );
  }

  &.inverted.dullyellow {
    @include button-variant(
      color.scale(palette(dullyellow), $lightness: 20%),
      palette(white),
      color.scale(palette(dullyellow), $lightness: 10%),
      palette(dullyellow)
    );
  }

  // layout modifiers
  &.block {
    display: block;
    width: 100%;
    text-align: center;
  }

  &.rounded {
    border-radius: 3rem;

    &::after {
      border-radius: 3rem;
    }
  }

  &.small {
    padding: 0 0.75rem;
    line-height: 2rem;
    font-size: 1rem;
  }

  &.large {
    padding: 0 1.5rem;
    line-height: 4rem;
    font-size: 1.5rem;
  }

  &.icon:not(.block) {
    padding: 0;
    width: 3rem;
    text-align: center;
  }

  // button icon
  .text + .icon {
    padding-left: 0.5rem;
    margin-left: 1rem;
    margin-right: -0.75rem;
  }

  .icon {
    position: relative;
    display: inline-block;
    width: 3rem;
    font-size: 0.8em;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      top: 0.5rem;
      bottom: 0.5rem;
      left: 0;
      width: 1px;
      background: rgba(palette(white), 0.25);
    }
  }

  &.small {
    .text + .icon {
      padding-left: 0.25rem;
      margin-left: 0.5rem;
      margin-right: -0.5rem;
    }

    .icon {
      width: 2rem;
    }
  }

  &.large {
    .text + .icon {
      padding-left: 0.75rem;
      margin-left: 1.5rem;
      margin-right: -1rem;
    }

    .icon {
      width: 4rem;
    }
  }
}

// button groups
.ui-button-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > .ui-button {
    flex: 0 0 auto;
    margin: 0.5rem 0;
    margin-right: 1rem;

    &.small {
      margin: 0.25rem 0;
      margin-right: 0.5rem;
    }

    &:last-child, &.small:last-child {
      margin-right: 0;
    }
  }
}
